import React from 'react';

function Contact() {
  return (
    <section className="o-section t-section o-section--footer">
      <div className="o-section__header-bg t-section__header" />
      <div className="o-section__content-bg t-section__content" />
      <div className="o-container">
        <div className="o-section__container">
          <header className="o-section__header t-section__header">
            <div className="o-content">
              <h2 className="o-section__heading">Contact</h2>
              <div className="o-content__body o-section__description">
                <div className="u-left-description">Call me, maybe.</div>
              </div>
            </div>
          </header>
          <div className="o-section__content t-section__content ">
            <div className="c-footer__contact">
              <div className="o-grid">
                <div className="o-grid__col-md-4 o-grid__col-sm-6">
                  <div className="a-header o-content">
                    <div className="o-content__body">
                      <h4>Location</h4>
                      <address>Lisbon, Portugal</address>
                    </div>
                  </div>
                </div>
                <div className="o-grid__col-md-4 o-grid__col-sm-6">
                  <div className="a-header o-content">
                    <div className="o-content__body">
                      <a
                        href="tel:+351917717213"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="t-link-container"
                      >
                        <h4>Phone</h4>
                        <p>
                          <span className="t-link-container__item--blended">
                            +351 917 717 213
                          </span>
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="o-grid__col-md-4 o-grid__col-sm-6">
                  <div className="a-header o-content">
                    <div className="o-content__body">
                      <a
                        href="mailto:tiago@tiagopereira.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="t-link-container"
                      >
                        <h4>Email</h4>
                        <p>
                          <span className="t-link-container__item--blended">
                            tiago@tiagopereira.com
                          </span>
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="c-footer__contact-divider" />
            <div className="o-content">
              <div className="c-footer__bottom">
                <div className="c-footer__brand">
                  <div className="c-brand">
                    <div className="o-content__body">
                      <h1 className="c-brand__title t-title">
                        <span className="c-brand__sizer c-brand__sizer--sm">
                          <span className="a-footer c-brand__first-word t-title__first-word">
                            Tiago Pereira
                          </span>
                        </span>
                      </h1>
                    </div>
                  </div>
                </div>
                <ul className="c-footer__social-buttons c-social-buttons o-content__body">
                  <li className="a-footer">
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        setTimeout(function () {
                          window.print();
                        }, 1000);
                      }}
                      rel="noopener noreferrer"
                      className="c-social-button c-resume-button t-social-button"
                    >
                      <i className="fas fa-lg fa-file-pdf"></i> Download CV
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
