import React from 'react';
import Fade from 'react-reveal/Fade';
import profile from '../assets/images/profile.jpg';

function Header() {
  return (
    <section className="o-section o-section--header t-section t-section--header">
      <div className="c-header">
        <div className="o-section__header-bg t-section__header" />
        <div className="o-section__content-bg t-section__content" />
        <div className="o-container">
          <div className="o-section__container">
            <header className="o-section__header c-header__header t-section__header">
              <div className="c-header__inner-header">
                <div className="c-header__avatar">
                  <div className="a-header c-avatar">
                    <Fade delay={50}>
                      <img
                        className="c-avatar__img"
                        src={profile}
                        alt="Tiago Pereira"
                      />
                    </Fade>
                  </div>
                </div>
              </div>
            </header>
            <div className="o-section__content c-header__content t-section__content">
              <div className="c-header__inner-content">
                <div className="c-header__top">
                  <div className="c-header__brand">
                    <div className="c-brand">
                      <Fade delay={100}>
                        <h1 className="c-brand__title t-title">
                          <span className="c-brand__sizer">
                            <span className="a-header c-brand__first-word t-title__first-word">
                              Tiago Pereira
                            </span>
                          </span>
                        </h1>
                      </Fade>
                      <Fade delay={150}>
                        <h2 className="a-header c-brand__sub-title t-sub-title">
                          <span className="c-brand__sizer">
                            Full Stack Developer
                          </span>
                        </h2>
                      </Fade>
                    </div>
                  </div>
                  <Fade delay={300}>
                    <ul className="c-header__social-buttons c-social-buttons">
                      <li className="a-header">
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            window.scrollTo({
                              top: document.body.scrollHeight,
                              behavior: 'smooth',
                            });
                            setTimeout(function () {
                              window.print();
                            }, 1000);
                          }}
                          rel="noopener noreferrer"
                          className="c-social-button c-resume-button t-social-button"
                        >
                          <i className="fas fa-lg fa-file-pdf"></i> Download CV
                        </a>
                      </li>
                    </ul>
                  </Fade>
                </div>
                <Fade delay={250}>
                  <div className="c-header__contact">
                    <hr className="a-header c-header__contact-divider" />
                    <div className="o-grid">
                      <div className="o-grid__col-md-4 o-grid__col-sm-6">
                        <div className="a-header o-content">
                          <div className="o-content__body">
                            <h4>Location</h4>
                            <address>Lisbon, Portugal</address>
                          </div>
                        </div>
                      </div>
                      <div className="o-grid__col-md-4 o-grid__col-sm-6">
                        <div className="a-header o-content">
                          <div className="o-content__body">
                            <a
                              href="tel:+351917717213"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="t-link-container"
                            >
                              <h4>Phone</h4>
                              <p>
                                <span className="t-link-container__item--blended">
                                  +351 917 717 213
                                </span>
                              </p>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="o-grid__col-md-4 o-grid__col-sm-6">
                        <div className="a-header o-content">
                          <div className="o-content__body">
                            <a
                              href="mailto:tiago@tiagopereira.com"
                              target="_blank"
                              rel="noopener noreferrer"
                              className="t-link-container"
                            >
                              <h4>Email</h4>
                              <p>
                                <span className="t-link-container__item--blended">
                                  tiago@tiagopereira.com
                                </span>
                              </p>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Header;
